<template>
  <b-modal
    id="modal-custom-fields" size="lg"
    hide-header
    hide-footer
    @hidden="verifyCustomFields"
    content-class="border-radius modal-content"
  >
    <!-- <slot name="modal-header-close">
      <div class="close" @click="hideModal">×</div>
    </slot> -->
    <slot name="modal-title">
      <p class="text1">
        Preencha os campos abaixo
      </p>
    </slot>
    <slot name="default">
      <div class="allContentModal">
        <div class="spaceText2">
          <p class="fade-one text2">
            <b class="checklist">✓</b
            >É necessario preencher os campos para ter acesso a plataforma.
          </p>
        </div>
        <div>
        <div v-for="(find, index) in finds" :key="find.label">
          <b-form-group :label="find.name" name-for="name">
            <b-form-input
              v-if="find.type === 'text'"
              v-model="find.value"
              :placeholder="find.name"
              :key="index"
            ></b-form-input>
            <b-form-textarea
              v-if="find.type === 'textarea'"
              v-model="find.value"
              :placeholder="find.name"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>
      <div class="footer">
      </div>
        <div>
        <button class="fade-five button disabled" @click="salvarDados">Salvar</button>
        </div>
      </div>
    </slot>
  </b-modal>
</template>

<script>
import CustomFieldsService from "@/services/resources/CustomFieldsService";
const service = CustomFieldsService.build();
import MemberMetaService from "@/services/resources/MemberMetaService";
const serviceMemberMeta = MemberMetaService.build();


import Cookies from "js-cookie";

export default {
  data() {
    return {
      finds: [],
      memberId: null,
      siteId: null,
    };
  }, 
  computed: {
    currentSite() { 
      return this.$store.getters.currentSite;
    },
  },
  methods: {
    verifyCustomFields(){
      setTimeout(() => {
        let data = {
          id: "/site/check-custom"
        }
        serviceMemberMeta
          .read(data)
          .then((resp) => {
            if(resp.filled_data === false){
              this.$root.$emit("bv::show::modal", "modal-custom-fields", "#btnShow");
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      }, 3000);
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-custom-fields", "#btnShow");
    },
    getCurrentSiteAndMember() {
      this.siteId = this.currentSite.currentSite.id;
      this.memberId = this.currentSite.currentMember.id;
    },
    salvarDados() {
      //console.log(this.finds);
      var campos = this.finds;
      for (let i = 0; i < campos.length; i++) {
        const campo = campos[i];

        let data = {
          id:
            "/site/" +
            this.memberId +
            "/" +
            this.siteId +
            "/" +
            campo.name,
          value: campo.value,
        };

        this.$root.$emit("loadOn");
        serviceMemberMeta
          .postID(data)
          .then((resp) => {
            //console.log("save member meta", resp);
            Cookies.set("userCustomFields", true);
            this.$store.dispatch("updateUserCustomFields");
            this.$root.$emit("bv::hide::modal", "modal-custom-fields", "#btnShow");
            this.$root.$emit("loadOff");
          })
          .catch((err) => {
            //console.log(err);
            this.$root.$emit("loadOff");
          });
      }
    },
    getCustomFields() {
      service
        .search()
        .then((resp) => {
          //console.log("get-fields", resp);
          this.finds = resp;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
  mounted() {
    this.getCurrentSiteAndMember();
    this.getCustomFields();
  },
};
</script>
<style lang="scss">
.border-radius {
  border-radius: 30px;
}
.modal-content {
  background-color: var(--background-v2) !important;
}

#modal-custom-fields {
  z-index: 99999999999;
  .modal-header {
    border: none;
  }
}
</style>
<style lang="scss" scoped>
.close {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 40px;
  opacity: 1;
  text-shadow: inherit;
  font-weight: lighter;
  margin-top: 9px;
  cursor: pointer;
  color: var(--fontcolor-v2);
  background: var(--background2-v2);
}

.checklist {
  color: var(--maincolor);
  margin-right: 13px;
}
.fade-one {
  animation: fadeIn 1s;
}
.fade-two {
  animation: fadeIn 5s;
}
.fade-three {
  animation: fadeIn 9s;
}
.fade-four {
  animation: fadeIn 13s;
}

.fade-five {
  animation: fadeIn 17s;
}

@keyframes fadeIn {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

#modal-custom-fields {
  .allContentModal {
    padding: 0px 10px;
    margin: 0 auto;
    display: block;
    border-radius: 30px;
    text-align: -webkit-left;
  }
  .spaceText2 {
    margin-bottom: 20px;
  }
  .spaceText1 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .text1 {
    font-family: "Montserrat Alternates";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    letter-spacing: 0.2px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 40px;
    color: var(--fontcolor-v2);
  }
  .text2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    letter-spacing: 0.2px;
    font-size: 17px;
    color: var(--fontcolor-v2);
  }

  .button {
    width: 100%;
    height: 55px;
    border-radius: 30px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;

    border: none;
    margin-top: 30px;

    background: var(--maincolor);
    color: var(--fontcolor3-v2);
  }
}
</style>
